import Vue from 'vue'
import Router from 'vue-router'

const Index = () => import('views/index/index')
const IndexDetail = () => import('views/index/indexDetail')
const Service = () => import('views/service/service')
const Case = () => import('views/case/case')
const Info = () => import('views/info/info')
const CaseDetail = () => import('views/case/caseDetail')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index
    },
    {
      path: '/indexDetail',
      name: 'indexDetail',
      component: IndexDetail
    },
    {
      path: '/service',
      name: 'service',
      component: Service
    },
    {
      path: '/case',
      name: 'case',
      component: Case
    },
    {
      path: '/info',
      name: 'info',
      component: Info
    },
    {
      path: '/caseDetail',
      name: 'caseDetail',
      component: CaseDetail
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  }
})
