<template>
  <div id="app">
    <keep-alive>
      <router-view id="view"></router-view>
    </keep-alive>
  </div>
</template>

<script type="text/ecmascript-6">
  import flexible from 'common/js/flexible'

  export default {
    name: 'app',
    created() {
      flexible()
      window.onresize = function () {
        flexible()
      }
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  #app
    height: 100%;
    font-size 0
</style>
